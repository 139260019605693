<template>
  <main class="register-reseller w-100 blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <section class="register-reseller__numbers">
      <span class="register-reseller__number register-reseller__number_active">1</span>
      <span class="register-reseller__number register-reseller__number_active">2</span>
      <span class="register-reseller__number">3</span>
      <span class="register-reseller__number">4</span>
    </section>
    <h1 class="title title__h1 register-reseller__title">{{ $t("REGISTER_RESELLER.TITLE2") }}</h1>
    <p class="text register-reseller__text">{{ $t("REGISTER_RESELLER.ENTER_FOLLOWING_DETAILS") }}</p>
    <section class="reseller-owner register-reseller__reseller-owner">
      <div class="reseller-owner__container">
        <form class="reseller-owner__cover">
          <div class="reseller-owner__wrapper">
            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">*{{ $t("COMMON.FIRST_NAME") }}</label>
              <input class="input" type="text" />
            </div>

            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">{{ $t("COMMON.LAST_NAME") }} *</label>
              <input class="input" type="text" />
            </div>

            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">{{ $t("COMMON.ID_NUMBER_PASSPORT") }}</label>
              <input class="input" type="text" />
            </div>

            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">{{ $t("TOURISM_PROFIT_PERCENTAGES.MOBILE_PHONE") }} *</label>
              <input class="input" type="tel" />
            </div>

            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">{{ $t("COMMON.HOME_ADDRESS") }}</label>
              <input class="input" type="text" />
            </div>

            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">{{ $t("LOGIN.USERNAME") }}</label>
              <input class="input" type="text" />
            </div>

            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">{{ $t("COMMON.E_MAIL") }}</label>
              <input class="input" type="email" />
            </div>

            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">{{ $t("COMMON.POSTAL_CODE") }}</label>
              <input class="input" type="text" />
            </div>

            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">{{ $t("COMMON.PASSWORD") }}</label>
              <input class="input" type="text" />
            </div>
          </div>
        </form>

        <button class="button reseller-owner__button">
          <div class="reseller-owner__plus"></div>{{ $t("REGISTER_RESELLER.ADDING_ADDITIONAL_OWNERS") }}
        </button>
      </div>
      <a href="register-reseller-2.html" class="link reseller-owner__link">{{ $t("COMMON.CONTINUE") }}</a>
    </section>
  </main>
</template>

<script>
export default {
  name: "RegisterReseller2",
};
</script>
<style lang="scss" scoped>
.ltr-type {
  .reseller-owner__plus {
    margin-left: unset;
    margin-right: 16px;
  }
}
</style>